.show_list {
    display: block;
}

body {
    /* font-family: "Poppins", sans-serif !important; */
    font-family: "Manrope", sans-serif !important;
    width: 100% !important;
    height: 100%;
    overflow-x: hidden;
    position: relative;
}

/* PRELOADER CSS */
.page-loader {
    width: 100%;
    height: 100%;
    position: absolute;
    /* background: #272727; */
    /* background: #784bbc; */
    /* background: #393939; */

    background: rgb(252, 179, 8);
    background: linear-gradient(270deg, rgba(252, 179, 8, 1) 0%, rgba(250, 156, 8, 1) 100%);
    z-index: 1000;
}

.page-loader .txt {
    /* color: #666; */
    color: #ffffff;
    text-align: center;
    top: 14%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
}

/* SPINNER ANIMATION */
.spinner {
    position: relative;
    top: 14.5%;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    background-color: #fff;

    border-radius: 100%;
    -webkit-animation: sk-scaleout 1s infinite ease-in-out;
    animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 0;
    }
}

@keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

.container {
    padding: 0 !important;
}

section {
    width: 100% !important;
    overflow-x: hidden !important;
}

/* Navbar section */

header {
    position: fixed;
    top: 0;
    z-index: 5;
}

.nav {
    width: 100%;
    height: 90px;
    position: fixed;
    line-height: 65px;
    text-align: center;
    background: #ffff;
    box-shadow: 0px 3px 12px #00000029;
}

.nav div.logo {
    float: left;
    width: 149px;
    height: 55px;
}

.nav div.main_list {
    height: 65px;
    float: right;
}

.nav div.main_list ul {
    width: 100%;
    height: 65px;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav div.main_list ul li {
    width: auto;
    height: 65px;
    padding: 0;
    padding-right: 37px;
}

.nav div.main_list ul li a {
    text-decoration: none;
    color: #393939;
    line-height: 25px;
    font-size: 16px;
    font-weight: 400;
}

.nav div.main_list ul li a:hover {
    color: #784bbc;
}

.nav div.main_list ul li .btn-sign {
    padding: 8px 33px;
    background: #784bbc;
    border-radius: 10px;
    color: #ffffff;
    line-height: 25px;
    font-size: 16px;
    font-weight: 400;
    border: none;
}

.navTrigger {
    display: none;
}

.nav {
    padding-top: 16px;
    padding-bottom: 18px;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

/* Animation */

.navTrigger {
    cursor: pointer;
    width: 30px;
    height: 25px;
    margin: auto;
    position: absolute;
    right: 30px;
    top: 0;
    bottom: 0;
}

.navTrigger i {
    background-color: #4f0973;
    border-radius: 2px;
    content: "";
    display: block;
    width: 100%;
    height: 4px;
}

.navTrigger i:nth-child(1) {
    -webkit-animation: outT 0.8s backwards;
    animation: outT 0.8s backwards;
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
}

.navTrigger i:nth-child(2) {
    margin: 5px 0;
    -webkit-animation: outM 0.8s backwards;
    animation: outM 0.8s backwards;
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
}

.navTrigger i:nth-child(3) {
    -webkit-animation: outBtm 0.8s backwards;
    animation: outBtm 0.8s backwards;
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
}

.navTrigger.active i:nth-child(1) {
    -webkit-animation: inT 0.8s forwards;
    animation: inT 0.8s forwards;
}

.navTrigger.active i:nth-child(2) {
    -webkit-animation: inM 0.8s forwards;
    animation: inM 0.8s forwards;
}

.navTrigger.active i:nth-child(3) {
    -webkit-animation: inBtm 0.8s forwards;
    animation: inBtm 0.8s forwards;
}

@-webkit-keyframes inM {
    50% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(45deg);
    }
}

@keyframes inM {
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(45deg);
    }
}

@-webkit-keyframes outM {
    50% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(45deg);
    }
}

@keyframes outM {
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(45deg);
    }
}

@-webkit-keyframes inT {
    0% {
        -webkit-transform: translateY(0px) rotate(0deg);
    }
    50% {
        -webkit-transform: translateY(9px) rotate(0deg);
    }
    100% {
        -webkit-transform: translateY(9px) rotate(135deg);
    }
}

@keyframes inT {
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(9px) rotate(0deg);
    }
    100% {
        transform: translateY(9px) rotate(135deg);
    }
}

@-webkit-keyframes outT {
    0% {
        -webkit-transform: translateY(0px) rotate(0deg);
    }
    50% {
        -webkit-transform: translateY(9px) rotate(0deg);
    }
    100% {
        -webkit-transform: translateY(9px) rotate(135deg);
    }
}

@keyframes outT {
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(9px) rotate(0deg);
    }
    100% {
        transform: translateY(9px) rotate(135deg);
    }
}

@-webkit-keyframes inBtm {
    0% {
        -webkit-transform: translateY(0px) rotate(0deg);
    }
    50% {
        -webkit-transform: translateY(-9px) rotate(0deg);
    }
    100% {
        -webkit-transform: translateY(-9px) rotate(135deg);
    }
}

@keyframes inBtm {
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(-9px) rotate(0deg);
    }
    100% {
        transform: translateY(-9px) rotate(135deg);
    }
}

@-webkit-keyframes outBtm {
    0% {
        -webkit-transform: translateY(0px) rotate(0deg);
    }
    50% {
        -webkit-transform: translateY(-9px) rotate(0deg);
    }
    100% {
        -webkit-transform: translateY(-9px) rotate(135deg);
    }
}

@keyframes outBtm {
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(-9px) rotate(0deg);
    }
    100% {
        transform: translateY(-9px) rotate(135deg);
    }
}

a {
    text-decoration: none !important;
    color: inherit !important;
}

footer.m-footer {
    padding: 64px 83px 89px 66px;
    margin-bottom: 75px;
    background-color: #3838381a;
    border-radius: 10px;
}

footer.m-footer h6 {
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
    color: #393939;

    margin-bottom: 0;
}

footer.m-footer h6.footer-address {
    margin-top: 34px;
}

footer.m-footer h5 {
    font-size: 16px;
    line-height: 25px;
    font-weight: 600;
    color: #393939;
    margin-bottom: 0;
}

footer.m-footer ul {
    list-style: none;
    padding: 0;
    padding-top: 25px;
}

footer.m-footer ul li a {
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    color: #393939;
}

.footer-contact-no {
    padding-top: 25px;
}

.footer-contact-mail {
    padding-top: 20px;
}

.footer-contact-social {
    display: inline;
}

.footer-contact-social img {
    margin-left: 20px;
    margin-top: 25px;
}

.footer-contact-social img:first-child {
    margin-left: 0px;
}

.home-banner {
    margin-top: 90px;
}

.home-banner-div-content {
    background: #fffdec;
    padding-left: 10% !important;
    padding-top: 79px !important;
    padding-bottom: 96px !important;
}

.home-banner-div-content h4 {
    font-size: 40px;
    line-height: 50px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 0;
}

.home-banner-div-content h5 {
    font-size: 25px;
    line-height: 50px;
    font-weight: 700;
    color: #8654d2;
    margin-bottom: 0;
    padding-top: 65px;
}

.home-banner-div-content h6 {
    font-size: 16px;
    line-height: 50px;

    font-weight: 400;
    color: #000000;
    /*
      padding-top:18px; */
    margin-bottom: 0;
}

.home-banner-div-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.app-link {
    display: inline;
}

.pass-percentage {
    background: #784bbc;
    border-radius: 10px;
    padding: 26px 24px 26px 22px;

    width: 128px;
    height: 128px;
    position: absolute;
    top: 245px;
    left: 49.7%;
}

.pass-percentage h5 {
    font-size: 29px;
    line-height: 43px;
    font-weight: 700;
    color: #ffffff;
    margin: 0;
    padding: 0;
    text-align: center;

    margin-bottom: 0;
}

.pass-percentage h6 {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #ffffff;
    margin: 0;
    text-align: center;

    margin-bottom: 0;
}

.home-banner .col-md-7 {
    width: 57% !important;
}

.home-banner .col-md-5 {
    width: 43% !important;
}

.active-studentse {
    background: #4f0973;
    border-radius: 10px;
    padding: 26px 24px 26px 22px;

    width: 180px;
    height: 120px;
    position: absolute;
    top: 424px;
    left: 54.5%;
}

.active-studentse h5 {
    font-size: 29px;
    line-height: 43px;
    font-weight: 700;
    color: #ffffff;
    margin: 0;
    padding: 0;
    text-align: center;

    margin-bottom: 0;
}

.active-studentse h6 {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #ffffff;
    margin: 0;
    text-align: center;
}

.home-about h3 {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    color: #4f0973;
    text-align: center;

    padding-top: 62px;
    padding-bottom: 32px;
    margin-bottom: 0;
}

.home-about h5 {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: #393939;
    text-align: center;

    padding-top: 0px;
    padding-bottom: 32px;
    margin-bottom: 0;

    width: 80%;
    margin: auto;
}

.home-about-img {
    padding-right: 50px;
}

.home-about-img img {
    object-fit: cover;
}

.home-about-img-con {
    display: flex;
    align-items: center;
}

.home-about-img-con h6 {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    color: #393939;
    margin-bottom: 0;
}

.home-course h3 {
    padding-top: 57px;
    padding-bottom: 32px;
    text-align: center;

    font-size: 40px;
    font-weight: 500;
    line-height: 60px;
    color: #4f0973;
    text-align: center;
    margin-bottom: 0;
}

.home-course-det {
    background: #784bbc1a;
    border-radius: 10px;
    padding: 60px 64px 60px 58px;

    margin-left: 0 !important;
    margin-right: 0 !important;
}

.home-course-det img {
    float: right;
    padding-left: 50px;
}

.home-course-det-cont {
    display: grid;
    align-items: center;
    align-content: center;
}

.home-course-det-cont h4 {
    padding-bottom: 32px;

    font-size: 30px;
    font-weight: 600;
    line-height: 46px;
    color: #000000;
    margin-bottom: 0;
}

.home-course-det-cont h6 {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    color: #393939;
    width: 90%;
    margin-bottom: 0;
}

.home-student-placed h3 {
    padding-top: 64px;
    padding-bottom: 22px;

    text-align: center;
    font-size: 40px;
    font-weight: 500;
    line-height: 60px;
    color: #4f0973;
    margin-bottom: 0;
}

.home-student-placed-imgs {
    display: flex;
    justify-content: space-between;
}

.home-testimonilas {
    background: #3838381a;
    border-radius: 10px;

    padding: 60px 59px 56px 59px;

    margin-left: 0 !important;
    margin-right: 0 !important;
}

.home-testimonilas h3 {
    text-align: center;
    font-size: 40px;
    font-weight: 500;
    line-height: 60px;
    color: #4f0973;
}

.home-testimonilas h5 {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: #393939;
    width: 90%;
    margin: auto;
}

.testimonilas-card {
    background: #ffffff;
    border-radius: 10px;
    padding: 44px 43px 46px 43px;
}

.testimonilas-card h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    color: #4f0973;
    padding-left: 55px;
    padding-bottom: 8px;
    margin-bottom: 0;
}

.testimonilas-card h4 {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: #000000;
    padding-left: 55px;
    margin-bottom: 0;
}

.testimonilas-card h6 {
    padding-top: 47px;

    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    color: #393939;
    margin-bottom: 0;
}

.testimonilas-card {
    position: relative;
}

.testimonilas-img {
    position: absolute;
    top: 0;
    left: -67px;

    width: 134px !important;
    height: 134px;
    border-radius: 50%;
}

/* .testi-card{
  padding-left: 67px;
} */
.owl-carousel-testimonilas-card {
    padding-top: 47px;
}

.owl-carousel-testimonilas-card .owl-prev {
    position: absolute;
    top: 50%;
    left: 0;
}

.owl-carousel-testimonilas-card .owl-next {
    position: absolute;
    right: 0;
    top: 50%;
}

.owl-carousel-testimonilas-card .owl-dot.active {
    background: #4f0973 !important;
}

.owl-dots button.owl-dot {
    border: 1px solid #4f0973 !important;
    background: transparent !important;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    position: relative;
    margin: 10px;
}

.owl-dots {
    display: flex;
    justify-content: center;
    margin-top: 56px;
}

.home-reg-call {
    background: #4f0973;
    padding: 59px 80px 80px 80px;
    border-radius: 10px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 50px;
}

.home-reg-call h3 {
    font-size: 40px;
    font-weight: 500;
    line-height: 60px;
    color: #ffffff;
    margin-bottom: 0;
    text-align: center;
    padding-bottom: 42px;
}

.home-reg-call img {
    padding-right: 60px;
}

.home-reg-contact input {
    background: #ffffff;
    border: none;
    padding: 14px 37px;
    margin-bottom: 10px;
    border-radius: 10px;
    width: 100%;
}

.btn-sent {
    background: #ffffff;
    border: none;
    padding: 14px 37px;
    margin-bottom: 10px;
    border-radius: 10px;
    float: right;
    margin-top: 36px;
}

.home-reg-contact select {
    background: #ffffff;
    border: none;
    padding: 14px 37px;
    margin-bottom: 10px;
    border-radius: 10px;
    width: 100%;

    background: white;
    background-image: url("../images/Icon\ awesome-caret-down.svg");
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    appearance: none;
}

.home-reg-contact select:focus-visible {
    outline: none;
}

/* User Home */
.user-home-sec {
    background: #3838381a;
    padding-top: 154px;
    padding-bottom: 64px;
}

.user-heade {
    display: flex;
}

.user-header {
    display: flex;
    justify-content: space-between;
    width: -webkit-fill-available;
    margin-left: 8.7%;
}

.user-header-search input[type="search"] {
    border: 1px solid #707070;
    border-radius: 10px;
    /* padding: 9px 33px; */
    padding: 9px;
    height: 40px;
    width: 600px;
    text-align: end;
}

.user-header-search input:focus-visible {
    outline: none;
}

.user-header .profile a:first-child img {
    margin-right: 22px;
}

.user-header .profile a:nth-child(2) img {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    object-fit: cover;
}

.user-home-sec h4 {
    font-size: 20px;
    font-weight: 400;
    line-height: 50px;
    color: #000000;
    margin-bottom: 0;
    padding-bottom: 14px;
}

.user-home-sec h3 {
    font-size: 40px;
    font-weight: 400;
    line-height: 50px;
    color: #000000;
    margin-bottom: 0;
    padding-bottom: 29px;
}

.upcoming-class {
    display: flex;
    /* justify-content: space-between; */
}

.upcoming-class-card {
    width: 30% !important;
    padding: 30px !important;

    background: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
}

.upcoming-class-card img {
    width: 100%;
    object-fit: cover;
    height: 148px;
    border-radius: 10px;
}

h3.class-title {
    margin-top: 24px;
    margin-bottom: 20px;

    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    color: #000000;
    margin-bottom: 0;

    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    cursor: pointer;
    word-break: break-all;
}

h3.class-title:hover {
    overflow: visible;
    white-space: normal;
}

.class-cont-rows {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
}

.class-cont-rows h5 {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: #707070;
    margin-bottom: 0;
}

.class-cont-rows h5.class-topic,
.class-cont-rows h5.class-topic-sec {
    cursor: pointer;
    word-break: break-all;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 140px;
    transition: 0s;
}

.class-cont-rows h5.class-topic:hover,
.class-cont-rows h5.class-topic-sec:hover {
    overflow: visible;
    white-space: normal;
}

.upcoming-class-day-btn {
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
    width: 100%;
    border-radius: 10px;
    background: #784bbc;
    border: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    margin-top: 16px;
}

.upcoming-class-join-btn {
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
    width: 100%;
    border-radius: 10px;
    background: #4f0973;
    border: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    margin-top: 16px;
}

.upcoming-class-time-btn {
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
    width: 100%;
    border-radius: 10px;
    /* background: #393939; */
    background: rgb(252, 179, 8);
    background: linear-gradient(270deg, rgba(252, 179, 8, 1) 0%, rgba(250, 156, 8, 1) 100%);
    border: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    margin-top: 16px;
}

.user-home-footer {
    margin-top: 58px;
}

.user-home-footer {
    background: #4f0973;
}

.user-home-footer h5,
.user-home-footer h6,
.user-home-footer ul li a {
    color: #ffffff !important;
}

.profile-list {
    display: none;
    margin-top: 50px;
    position: absolute;
    top: 0;
    right: 0;
    width: max-content;
}

.profile-list-no-cont {
    height: 25px;
}

.profile-list-cont {
    padding: 36px 50px 30px 50px;
    background: #4f0973;
    border-radius: 0px 0px 10px 10px;
}

/* .profile-list-icon:hover .profile-list{
  display: block;
}
.profile-list:hover{
  display: block;
} */
.profile-list.active {
    display: block;
}

.user-header .profile {
    position: relative;
}

.profile-list-cont-row {
    display: flex;
    padding-top: 12.5px;
    padding-bottom: 12.5px;
    border-bottom: 1px solid #ffffff54;
}

.profile-list-cont-row:last-child {
    border-bottom: 0px;
}

.profile-list-cont-row h6 {
    margin-left: 24px;
    margin-bottom: 0;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
}

.profile-list-cont-row img {
    width: 22px !important;
    height: 22px !important;
    border-radius: 0 !important;
    object-fit: none !important;
}

.profile-logout img {
    width: 22px !important;
    height: 22px !important;
    margin-right: 20px;
}

.profile-logout {
    border: 1px solid #ffffff54;
    border-radius: 10px;
    width: 100%;
    padding: 11px 32px !important;
    background: transparent;
    height: 45px;
    margin-top: 30px;
    color: #ffffff;
    line-height: initial !important;
}

.user-mob-header-search {
    display: none;
}

.header-mob-list {
    display: none !important;
}

.main_list.user-header.show_list .header-mob-list {
    display: block !important;
}

/* Profile Edit */
.prof-edit-sec {
    background: #3838381a;
    padding-top: 154px;
    padding-bottom: 59px;
    margin-bottom: 58px;
}

.prof-edit-card {
    background: #ffffff;
    border-radius: 10px;
    padding: 55px 43px 54px 59px !important;


}

.prof-edit-feld {
    background: #ebebeb;
    border-radius: 10px;
    padding: 12px 20px 12px 34px;
    margin-bottom: 17px;
    width: 98%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.prof-edit-feld input, .prof-edit-feld select {
    border: none !important;
    background: #00000000 !important;
    width: 85% !important;
}

.prof-edit-feld .field-ttl {
    width: 15% !important;
}

.prof-edit-feld h6 {
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    color: #707070;
    margin: 0;
}

.prof-edit-feld-title {
    width: 130px;
}

.edit-prof-pic {
    display: grid;
    justify-items: center;
}

.edit-prof-pic h6 {
    color: #393939;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    margin: 0;
    margin-left: 15px;
}

.edit-prof-pic .edit-link {
    display: flex;
    align-items: center;
    padding-top: 21px;
}

.edit-prof-pic-img {
    border-radius: 10px;
    width: 228px;
    height: 228px;
    object-fit: cover;
}

.reset-pass-btn {
    padding: 15px;
    width: 98%;
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    background: #4f0973;
    border-radius: 10px;
    border: none;
}

.user-home-perform-sec {
    margin-top: 154px;
}

.user-home-perform-sec h4 {
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    line-height: 50px;
    margin: 0;
}

.class-count {
    margin-top: 18px;
    margin-bottom: 24px;

    display: flex;
}

.class-count-categ {
    width: 260px;
    padding: 14px;
    text-align: center;
    margin-right: 30px;
    border-radius: 10px;

    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: #000000;
}

.class-count-categ.categ1 {
    background: #500a7380;
}

.class-count-categ.categ2 {
    background: #500a7340;
}

.class-count-categ.categ3 {
    background: #520a751a;
}

.perf-categs {
    background: #ebebeb;
    padding: 18px 110px 18px 23px !important;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    margin-bottom: 35px;
}

.perf-categs .perf-categ {
    padding: 9px 35px;
    color: #000000;
    /* font-size: 20px; */
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    text-transform: uppercase;
}

.perf-categs .perf-categ.active {
    color: #ebebeb;
    background: #4f0973;
    border-radius: 10px;
}

.user-home2-sec {
    background: #3838381a;
    padding-top: 69px;
    padding-bottom: 64px;
}

.user-home2-sec-title {
    font-size: 40px;
    font-weight: 400;
    line-height: 50px;

    margin-bottom: 29px;
}

.perform-graph {
    margin-bottom: 75px;
}

.user-home-recent-class {
    margin-top: 54px;
    /* margin-bottom: 22px; */
}

.recent-class-title {
    font-size: 40px;
    font-weight: 400;
    line-height: 50px;
    color: #000000;
    margin-bottom: 37px;
}

.recent-class-card {
    width: 48.6% !important;
    border: 2px solid #dedede;
    border-radius: 10px;
    background: #ebebeb;
    padding: 24px;
    margin-bottom: 20px;
}

/* .recent-class-card:nth-child(odd){
  margin-right: 24px;
} */
.recent-class-card {
    margin-right: 15px;
}

.recent-class-row {
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
}

.recent-class-card .col-md-3 {
    /* width: 27%; */
    width: 0%;
    display: none;
}

.recent-class-card .col-md-9 {
    /* width: 73%; */
    width: 100%;
}

.recent-class-card .col-md-3 img {
    object-fit: cover;
}

.recent-class-card .bookmark-bg {
    width: 40px;
    height: 40px;
    background: #ffffff;
    border-radius: 10px;

    justify-items: center;
    display: grid;
    align-items: center;
}

.recent-class-cont-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.recent-class-cont-row-cont {
    display: flex;
    justify-content: space-between;

    margin-right: 37px;
}

.recent-class-cont-row h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    color: #000000;
}

.recent-class-sub-title {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    cursor: pointer;
    word-break: break-all;
}

.recent-class-topic {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    cursor: pointer;
    word-break: break-all;
}

.recent-class-tutor {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    cursor: pointer;
    word-break: break-all;
}

.recent-class-sect {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    cursor: pointer;
    word-break: break-all;
}

.recent-class-sub-title:hover,
.recent-class-topic:hover,
.recent-class-tutor:hover,
.recent-class-sect:hover {
    overflow: visible;
    white-space: normal;
}

.recent-class-cont-row-cont h6 {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: #707070;
}

.bookmark-bg label {
    border-top: 10px solid #cfcfcf;
    border-right: 8px solid #cfcfcf;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #cfcfcf;
}

.bookmark-bg label.active {

    /* background: rgb(252,179,8);
    background: linear-gradient(270deg, rgba(252,179,8,1) 0%, rgba(250,156,8,1) 100%); */
    border-top: 10px solid rgb(252, 179, 8);
    border-right: 8px solid rgb(252, 179, 8);
    border-bottom: 8px solid transparent;
    border-left: 8px solid rgb(252, 179, 8);
    /* border-top: 10px solid #4f0973;
    border-right: 8px solid #4f0973;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #4f0973; */
}

.watch-video-btn {
    padding: 10px;
    width: 100%;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    background: #784bbc;
    border-radius: 10px;
    border: none;
    margin-top: 20px;
}

.attach-btn {
    padding: 10px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    color: #393939;
    width: 49%;

    background: #ffffff;
    border-radius: 10px;
    border: none;
}

.attachment-popup-btn button {
    appearance: none;
    border: none;
    background: transparent;
    width: 100%;
}

.attachment-popup-btn .dropdown-menu {
    border: none !important;
    border-radius: 10px !important;
    inset: 9px 8px auto -9px !important;
    padding: 10px 0;
}

.attachment-popup-btn .dropdown-item {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid #ebebeb;
    padding-bottom: 7.5px;
    padding-top: 10px;
}

.attachment-popup-btn .dropdown-menu li:last-child .dropdown-item {
    border-bottom: none;
}

.attachment-popup-btn .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #784bbca1 !important;
}

.dropdown-item:focus, .dropdown-item:hover {
    color: #1e2125;
    background: rgb(252, 179, 8);
    background: linear-gradient(270deg, rgba(252, 179, 8, 1) 0%, rgba(250, 156, 8, 1) 100%);
}

.attachment-popup-btn .dropdown-item label {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: #707070;

    /* overflow: hidden;
     display: -webkit-box;
     -webkit-line-clamp: 1; number of lines to show
             line-clamp: 1;
     -webkit-box-orient: vertical; */
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
}

.attachment-popup-btn button::after {
    content: none;
}

.attachment-popup-btn {
    padding: 10px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    color: #393939;
    width: 55%;

    background: #d9d9d9;
    border-radius: 10px;
    border: none;

    background-image: url(../images/Icon\ awesome-caret-down.svg);
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    appearance: none;
    background-size: 10px 10px;
}

.attachment-popup-test-btn {
    padding: 10px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    color: #ffffff;
    width: 35%;

    /* background: #393939; */
    border-radius: 10px;
    border: none;
    background: rgb(252, 179, 8);
    background: linear-gradient(270deg, rgba(252, 179, 8, 1) 0%, rgba(250, 156, 8, 1) 100%);
}

.mcq-btn {
    padding: 10px 35px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    color: #ffffff;
    /* width: 49%; */

    /* background: #393939; */
    background: rgb(252, 179, 8);
    background: linear-gradient(270deg, rgba(252, 179, 8, 1) 0%, rgba(250, 156, 8, 1) 100%);
    border-radius: 10px;
    border: none;
}

.recent-class-img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.class-count {
    padding: 0;
}

.my-classes-sec {
    padding-top: 90px;
    background: #3838381a;
    padding-bottom: 60px;
}

.saved-class-sec {
    padding-top: 90px;
    background: #ffffff;
    /* padding-bottom: 60px; */
}

.section-title {
    font-size: 40px;
    font-weight: 400;
    line-height: 50px;
    color: #000000;
    padding-top: 48px;
    padding-bottom: 29px;
}

.my-classes-sec .upcoming-class-card {
    margin-bottom: 37px;
    margin-right: 58px;
}

.my-classes-sec .upcoming-class-card:nth-child(3n + 3) {
    margin-right: 0px !important;
}

.load-more-btn1 {
    padding: 15px;
    background: #4f0973;
    border-radius: 10px;

    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    color: #ffffff;
    border: 0;
    width: 275px;
    margin-top: 23px;
}

.load-more-btn2 {
    padding: 15px;
    background: #ebebeb;
    border-radius: 10px;

    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    color: #000000;
    border: 0;
    width: 275px;
    margin-top: 23px;
}

.recent-class-card {
    margin-bottom: 46px;
}

.system-check-sec,
.my-tutors-sec {
    padding-top: 154px;
    background: #3838381a;
    min-height: 95vh;
    margin-bottom: 58px;
}

.system-check-title,
.my-tutors-title {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color: #000000;
    padding-bottom: 44px;
    margin: 0;
}

.system-check-list {
    display: flex;
    justify-content: space-between;
    padding-bottom: 33px;
}

.system-check-list-item {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 10px;
    padding: 25px 49px 24px 22px !important;

    width: 23.5% !important;
}

.system-check-list-item-text {
    padding-left: 34px;
}

.system-check-list-item-text h5 {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin: 0;
    padding-bottom: 5px;
}

.system-check-list-item-text h6 {
    color: #2ad102;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
}

.system-check-list-item-text h6.error {
    color: #e10000 !important;
}

.system-check-class-det-card {
    background: #ffffff;
    padding: 80px !important;

    border-radius: 10px;
    margin-bottom: 85px;
}

.system-check-class-tutor {
    display: flex;
    justify-content: center;
    padding-bottom: 48px;
}

.system-check-class-tutor-det {
    padding-left: 37px;
    padding-right: 37px;
}

.system-check-class-tutor img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
}

.system-check-class-tutor-det h4 {
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    line-height: 50px;
    margin: 0;
}

.system-check-class-tutor-det h5 {
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    line-height: 50px;
    margin: 0;
}

.system-check-class-info {
}

.system-check-class-info h6 {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 0;
    margin: auto;
    text-align: center;
    width: 40%;
}

.system-check-class-tutor.online {
    padding-bottom: 27px;
}

.system-check-class-tutor.online .system-check-class-tutor-det {
    position: relative;
}

.online-icon {
    background: #2ad102;
    width: 20px;
    height: 20px;
    border-radius: 50%;

    position: absolute;
    top: 14px;
    right: 0;
}

.start-class-btn {
    background: #784bbc;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    color: #ffffff;
    padding: 10px;
    border: none;
    width: 318px !important;
    margin-top: 30px;
}

.my-tutors-cont {
    padding-left: 37px;
}

.my-tutors-prof {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    object-fit: cover;
}

.online-indicater {
    background: #ffffff;
    width: 14px;
    height: 14px;
    border-radius: 50%;

    position: absolute;
    top: 8px;
    left: 38px;
}

.my-tutors-card-list {
    display: flex;
}

.online-indicater.online {
    background: #2ad102;
}

.my-tutors-card {
    background: #ffffff;
    padding: 20px 30px 20px 16px;
    border-radius: 10px;

    display: flex;
    justify-content: space-between;
    /* width: 31.5%!important; */
    margin-bottom: 20px;
}

.my-tutors-card-det {
    display: flex;
    position: relative;
}

.my-tutors-cont {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
}

.my-tutors-cont h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    color: #000000;
    margin: 0;
    padding-bottom: 5px;
}

.my-tutors-cont h5 {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: #393939;
    margin: 0;
}

.chat-sec {
    background: #3838381a;
    padding-top: 100px;
    padding-bottom: 53px;
    margin-bottom: 58px;
}

.chat-header {
    padding: 20px 16px;
    background: #ffffff;
    border-radius: 10px;
    /* margin-bottom: 40px; */
}

.chat-replay {
    background: #784abd1a;
    padding: 15px !important;
    border-radius: 10px;
    width: 50% !important;
}

.chat-replay h6 {
    color: #393939;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
}

.chat-replay-time {
    padding-left: 30px;
    /* padding-top: 14px; */

    color: #393939;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
}

.cha-talk {
    background: #784abd1a;
    padding: 30px !important;
    border-radius: 10px;
}

.cha-talk h6 {
    color: #393939;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
}

.chat-talk-time {
    padding-right: 30px;
    padding-top: 14px;

    color: #393939;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    float: right;
}

.chat-talk-cont {
    float: right;
    width: 50% !important;
}

.chat-talk-cont-row {
    justify-content: flex-end;
}

.text-input-field {
    padding: 15px 37px 15px 30px !important;
    background: #ffffff;
    display: flex;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    /* margin-top: 40px!important; */
    box-shadow: inset 0 3px 6px #00000029;
}

.mesenger-chat .text-input-field {
    padding: 15px 37px 15px 30px !important;
    background: #ffffff;
    display: flex;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    /* margin-top: 40px!important; */
    box-shadow: inset 0 3px 6px #00000029;
    position: absolute;
    bottom: 0;
    width: 98%;
}

.text-input-field input {
    border: none;
    width: 95%;
}

.chat-body {
    padding-top: 40px;
    padding-bottom: 40px;
    height: 50vh;
    overflow-y: scroll;
}

.mesenger-chat .chat-body {
    /* padding-top: 40px;
    padding-bottom: 40px; */
    padding-top: 40px;
    padding-bottom: 200px;
    height: 75vh;
    /* height: inherit; */
    overflow-y: scroll;
}

.chat-body::-webkit-scrollbar {
    display: none;
}

.chats-list {
    background: #ffffff;
    border-radius: 10px;
    padding: 2px 28px 26px 30px;
    height: 75vh;
    overflow-y: scroll;
}

.chats-list::-webkit-scrollbar {
    display: none;
}

.chats-list .my-tutors-cont {
    padding-left: 22px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    max-width: 85%;
}

.chats-list .my-tutors-card-det {
    padding-bottom: 22px;
    padding-top: 27px;
    border-bottom: 0.5px solid #707070;
}

.chats-list .my-tutors-card-det:last-child {
    border-bottom: none;
}

.chats-list .online-indicater {
    top: 35px;
}

.mesenger-chat {
    height: 75vh !important;
    overflow-y: hidden;
    position: relative;
}

.mesenger-chat::-webkit-scrollbar {
    display: none;
}

.mesenger-chat .chat-header {
    position: relative;
    top: 0;
}

.notification-sec {
    background: #3838381a;
    padding-top: 160px;
    padding-bottom: 53px;
    margin-bottom: 58px;

}

.notification-date-title {
    font-size: 20px;
    font-weight: 400;
    line-height: 50px;
    color: #000000;
    padding-top: 50px;
    padding-bottom: 20px;
    margin: 0;
}

.notification-today-title {
    font-size: 20px;
    font-weight: 400;
    line-height: 50px;
    color: #000000;
    padding-bottom: 20px;
    margin: 0;
}

.notification-bar.new {
    background: #784abd1a;
}

.notification-bar {
    padding: 15px 17px;
    background: #ffffff;
    border-radius: 10px;
    margin-bottom: 13px;

    display: flex;
    align-items: center;
}

.notification-bar h6 {
    margin: 0;
    padding-left: 25px;

    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
    color: #393939;
}

.notification-bar time {
    font-size: 16px;
    line-height: 25px;
    font-weight: 300;
    color: #393939;
    width: 8%;
}

.contact-card {
    background: #4f0973;
    padding: 80px !important;
    border-radius: 10px;
}

.contact-sec {
    padding-top: 115px;
}

.contact-card input[type="text"] {
    padding: 14px 37px;
    width: 100%;
    border-radius: 10px;
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
    color: #393939;
    border: none;
    margin-bottom: 10px;
}

.contact-card textarea {
    padding: 27px 37px;
    width: 100%;
    border-radius: 10px;
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
    color: #393939;
    border: none;
    margin-bottom: 22px;
    height: 208px;
}

.contact-img {
    padding-right: 60px;
    width: 100%;
    border-radius: 10px;
}

.contact-card input[type="file"] {
    background: url(../images/Icon\ material-attachmentcc.svg);
    background-repeat: no-repeat;
    background-position: center;

    height: 0;
    overflow: hidden;
    width: 0;
}

input[type="file"] + label {
    background: #ffffff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
    position: relative;
    vertical-align: middle;

    margin-right: 25px;
}

input[type="file"] + label {
    /* background-color: darken(#f15d22, 10%); */
    background: url(../images/Icon\ material-attachmentcc.svg) #ffffff;
    background-repeat: no-repeat;
    background-position: center;
    width: 50px;
    height: 50px;
}

.contact-btn-row {
    float: right;
}

.contact-btn-row .sent-btn {
    background: rgb(252, 179, 8);
    background: linear-gradient(270deg, rgba(252, 179, 8, 1) 0%, rgba(250, 156, 8, 1) 100%);
    /* background: #784bbc; */
    border-radius: 10px;
    border: none;
    width: 160px;
    height: 52px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
}

.modal-dialog {
    position: absolute;
    top: 50%;
    width: auto;
    margin: 0 !important;
    pointer-events: none;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    max-width: 60% !important;
}

.modal-content {
    padding: 63px 83px 69px 78px !important;
}

.modal-content.sign-in.Forgot-Password {
    padding: 120px 83px 120px 78px !important;
}

.modal-content.sign-in.otp {
    padding: 65px 83px 65px 78px !important;
}

.modal-content.sign-in.reset-Password {
    padding: 80px 83px 80px 78px !important;
}

.sign-in h3 {
    color: #393939;
    font-size: 50px;
    font-weight: 400;
    line-height: 76px;
    margin-bottom: 33px;
}

.sign-in input {
    padding: 14px 37px;
    margin-bottom: 24px;
    border: none;
    background: #ffffff;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 10px;
}

.forgot-pass {
    color: #4f0973;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    float: right;
}

.sign-in-btn {
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 14px;
    width: 100%;
    border: none;
    /* background: #393939; */
    background: rgb(252, 179, 8);
    background: linear-gradient(270deg, rgba(252, 179, 8, 1) 0%, rgba(250, 156, 8, 1) 100%);
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    margin-top: 47px;
}

.sign-in-modal {
    background: #500a73e6 !important;
}

.s404-sec {
    padding-top: 90px;
    position: relative;
    background: #784abd1a;
    margin-bottom: 40px;
}

.s404-card {
    background: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    margin: 50px 0px !important;
    height: 550px;
    position: relative;
}

.s404-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.s404-cont {
    position: absolute;
    top: 20%;
    left: 10%;
}

.s404-cont h3 {
    color: #4f0973;
    font-size: 90px;
    font-weight: 900;
    line-height: 76px;
    text-align: center;
    width: 35%;
}

.s404-cont h4 {
    color: #500a7380;
    font-size: 25px;
    font-weight: 500;
    line-height: 27px;
    text-align: center;
    width: 35%;
}

.s404-cont h6 {
    color: #393939;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    width: 35%;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
}

.s404-btn {
    border: none;
    padding: 10px 30px;
    /* background: #393939; */
    background: rgb(252, 179, 8);
    background: linear-gradient(270deg, rgba(252, 179, 8, 1) 0%, rgba(250, 156, 8, 1) 100%);
    border-radius: 10px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

.s404-btn-div {
    text-align: center;
    width: 35%;
}

.privacy-policy-sec {
    padding-top: 90px;
    position: relative;
    background: #784abd1a;
    margin-bottom: 40px;
}

.privacy-policy-card {
    background: #ffffff url(../images/imageedit_2_6985981321www.png) no-repeat center;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 70px 80px !important;
    position: relative;
    margin-bottom: 50px;
}

.privacy-policy-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 50px;
    color: #000000;
    padding-top: 50px;
    padding-bottom: 20px;
    margin: 0;
}

.privacy-policy-cont h6 {
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    color: #000000;
    padding-bottom: 10px;
    margin: 0;
}

.privacy-policy-cont p {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    color: #000000;
    padding-bottom: 15px;
    margin: 0;
}

#togglePassword,
#togglePassword2 {
    z-index: 100001;
    position: absolute;
    top: 25%;
}

/* Media qurey section */
@media (min-width: 1000px) {
    .home-banner-div-content {
        padding-left: 4.5% !important;
    }
}

@media (min-width: 1050px) {
    .home-banner-div-content {
        padding-left: 5.5% !important;
    }
}

@media (min-width: 1100px) {
    .home-banner-div-content {
        padding-left: 7.2% !important;
    }
}

@media (min-width: 1200px) {
    .home-banner-div-content {
        padding-left: 5.7% !important;
    }
}

@media (min-width: 1300px) {
    .home-banner-div-content {
        padding-left: 7.2% !important;
    }
}

@media (min-width: 1600px) {
    .container {
        max-width: 1500px !important;
    }
}

@media (min-width: 1800px) {
    .container {
        max-width: 1600px !important;
    }

    .home-banner-div-content {
        padding-left: 8% !important;
    }
}

@media (max-width: 2000px) and (min-width: 1400px) {
    .pass-percentage {
        left: 51.5%;
    }

    .recent-class-card {
        width: 32% !important;
    }

    .s404-card {
        height: 750px;
    }

    .home-reg-contact input {
        margin-bottom: 25px;
    }

    .home-reg-contact select {
        margin-bottom: 25px;
    }

    .contact-card input[type="text"] {
        margin-bottom: 25px;
    }
}

@media (max-width: 1600px) and (min-width: 1400px) {
    .recent-class-card .col-md-3 {
        padding-right: 0;
    }

    .recent-class-cont-row-cont h6 {
        font-size: 15px;
    }
}

@media (max-width: 1200px) {
    .my-classes-sec .upcoming-class-card {
        margin-right: 18px;
    }


    .home-student-placed-imgs {
        display: inline-block;
        text-align: center;
    }

    .upcoming-class-card {
        width: 32% !important;
    }

    .edit-prof-pic-img {
        width: 200px;
        height: 200px;
    }

    .system-check-list-item {
        padding: 25px 35px 24px 20px !important;
    }

    .system-check-list-item-text {
        padding-left: 30px;
    }

    .contact-img {
        padding-right: 10px;
    }

    .recent-class-card {
        margin-right: 10px;
    }
}

@media (max-width: 992px) {
    .mcq-btn {
        padding: 10px 25px;
    }

    .my-classes-sec .upcoming-class-card:nth-child(2n + 2) {
        margin-right: 0px !important;
    }

    .my-classes-sec .upcoming-class-card:nth-child(3n + 3) {
        margin-right: 28px !important;
    }

    .my-classes-sec .upcoming-class-card {
        margin-right: 28px;
    }


    .pass-percentage {
        top: 265px;
    }

    .home-about-img {
        display: grid;
        align-items: center;
        object-fit: cover;
    }

    .home-about h5 {
        font-size: 18px;
        width: 100%;
    }

    .home-course-det-cont-img {
        display: grid;
        align-items: center;
    }

    .home-course-det img {
        float: none;
        padding-left: 0px;
    }

    .home-course-det-cont h4 {
        padding-bottom: 15px;
        font-size: 24px;
        line-height: 35px;
        margin-bottom: 0;
    }

    .home-course-det-cont h6 {
        font-size: 14px;
        width: 100%;
    }

    .home-course h3 {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .home-reg-call-img {
        display: flex;
        align-items: center;
    }

    .home-reg-call img {
        object-fit: cover;
        padding-right: 0px;
        border-radius: 10px;
        height: 100%;
    }

    .upcoming-class-card {
        width: 48% !important;
        margin-bottom: 30px;
    }

    .user-header-search input[type="search"] {
        width: 300px;
    }

    .recent-class-card-row {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .recent-class-card .col-md-9 {
        width: 100%;
    }

    .recent-class-card .col-md-3 {
        width: 100%;
    }

    .recent-class-img {
        width: 100%;
        height: 130px;
        margin-bottom: 20px;
    }

    .system-check-list {
        display: flex;
        padding-bottom: 33px;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .system-check-list-item {
        width: 48.5% !important;
        margin-top: 25px;
    }

    .system-check-class-info h6 {
        width: 60%;
    }

    .contact-img {
        padding-right: 0px;
        height: 100%;
        object-fit: cover;
    }

    .prof-edit-feld-title {
        width: 120px;
    }

    .notification-bar time {
        width: 10%;
    }

    .notification-bar h6 {
        width: 85%;
    }

    .sign-in input {
        padding: 14px 27px;
    }

    .sign-in h3 {
        font-size: 30px;
    }
    .home-banner-div-content h4 {
        width: 90%;
    }
    .home-banner-div-content h5 {
        width: 90%;
    }
}

@media screen and (max-width: 768px) {


    .recent-class-sect {
        max-width: 90%;
    }


    .recent-class-cont-row-cont {
        flex-direction: column;
    }


    .recent-class-card .col-md-3 {
        /* width: 27%; */
        width: 100%;
        display: block;
    }


    .my-classes-sec .upcoming-class-card {
        margin-right: 0px !important;
    }


    .navTrigger {
        display: block;
    }

    .nav div.logo {
        margin-left: 15px;
    }

    .nav div.main_list {
        width: 100%;
        height: 0;
        overflow: hidden;

        position: absolute;
        left: 0;
        top: 90px;
    }

    .nav div.show_list {
        height: auto;
        /* display: none; */
    }

    .nav div.main_list ul {
        flex-direction: column;
        width: 100%;
        height: 100vh;
        right: 0;
        left: 0;
        bottom: 0;
        padding-top: 50px;
        background-color: #383838e0;
        background-position: center top;
    }

    .nav div.main_list ul li {
        width: 100%;
        text-align: start;
        margin-left: 18%;
    }

    .nav div.main_list ul li a {
        text-align: center;
        width: 100%;
        padding: 10px 0px;
        line-height: 25px;
        font-size: 20px;
        font-weight: 400;
    }

    .nav div.media_button {
        display: block;
    }

    .nav div.main_list ul li a {
        color: #fffafa !important;
    }

    .home-banner .col-md-7 {
        width: 100% !important;
    }

    .home-banner .col-md-5 {
        width: 100% !important;
    }

    .pass-percentage,
    .active-studentse {
        left: auto;
        right: 5%;
    }

    .home-about-img-con {
        padding-top: 20px;
        width: 95% !important;
        margin: auto;
    }

    .home-course-det-cont {
        padding-bottom: 20px;
    }

    .home-course-det {
        padding: 45px 30px 40px 30px;
    }

    .home-about-img-con {
        width: 87% !important;
    }

    .home-about h5 {
        width: 87%;
    }

    .home-student-placed h3 {
        padding-top: 30px;
        padding-bottom: 10px;
        font-size: 30px;
    }

    .home-testimonilas h3 {
        text-align: center;
        font-size: 30px;
    }

    .home-testimonilas h5 {
        font-size: 16px;
        line-height: 24px;
        width: 100%;
    }

    .home-testimonilas {
        padding: 45px 35px 40px 35px;
    }

    .home-reg-contact {
        padding-top: 25px;
    }

    .home-reg-call h3 {
        font-size: 30px;
    }

    .upcoming-class-card {
        width: 100% !important;
        margin-bottom: 30px;
    }

    .user-header {
        margin-left: 0%;
        background: #383838e0;
    }

    .user-header-search,
    .profile {
        display: none;
    }

    .header-mob-list {
        display: block;
    }

    .user-mob-header-search {
        background: #ffffff;
        display: flex;
        width: 100%;
        margin-top: 19px;
        justify-content: space-around;
        align-items: center;
    }

    .user-mob-header-search input[type="search"] {
        width: 80% !important;
    }

    .user-mob-header-search input[type="search"] {
        border: 1px solid #707070;
        border-radius: 10px;
        /* padding: 9px 33px; */
        padding: 9px;
        height: 40px;
        width: 600px;
        text-align: end;
    }

    .user-mob-header-search input:focus-visible {
        outline: none;
    }

    .user-header {
        top: 155px !important;
    }

    .profile-logout {
        width: 190px;
        margin-top: 6px;
    }

    .header-mob-list {
        background: #4f0973 !important;
    }

    .prof-edit-card-row {
        display: flex;
        flex-direction: column-reverse;
    }

    .edit-prof-pic {
        margin-bottom: 20px;
    }

    .prof-edit-sec {
        padding-top: 205px;
    }

    .class-count-categ {
        padding: 10px;
        font-size: 16px;
    }

    .perf-categs .perf-categ {
        padding: 6px 20px;
        font-size: 14px;
        line-height: 16px;
    }

    .perf-categs {
        padding: 15px 45px 15px 23px !important;
    }

    .recent-class-card {
        width: 100% !important;
    }

    .recent-class-img {
        height: 148px;
    }

    .my-classes-sec,
    .saved-class-sec {
        padding-top: 154px;
    }

    .section-title {
        font-size: 30px;
        line-height: 40px;
        padding-top: 30px;
        padding-bottom: 15px;
    }

    .system-check-sec,
    .my-tutors-sec {
        padding-top: 180px;
    }

    .system-check-title,
    .my-tutors-title {
        padding-bottom: 0px;
    }

    .system-check-class-det-card {
        padding: 50px 35px !important;
    }

    .my-tutors-card-list {
        padding-top: 25px;
    }

    .chat-sec {
        padding-top: 200px;
    }

    .chats-list-col {
        width: 95% !important;
        margin: auto;
    }

    .mesenger-chat {
        display: none;
    }

    .chats-list-col {
        width: 100% !important;
    }

    .chats-list {
        height: auto !important;
    }

    .text-input-field {
        padding: 20px 30px 20px 25px !important;
    }

    .chat-talk-cont {
        float: right;
        width: 75% !important;
    }

    .notification-sec {
        padding-top: 170px;
    }

    .contact-img {
        height: 200px;
        margin-bottom: 25px;
    }

    .contact-sec {
        padding-top: 180px;
    }

    .contact-card {
        padding: 50px !important;
    }

    .contact-btn-row {
        float: right;
        display: flex;
        align-items: center;
    }

    .modal-content {
        padding: 50px 45px 40px 45px !important;
    }

    .modal-dialog {
        max-width: 75% !important;
    }

    .sign-in h3 {
        font-size: 35px;
        line-height: 50px;
    }

    .s404-cont h3 {
        font-size: 50px;
        width: 50%;
        line-height: 40px;
        margin-bottom: 0;
    }

    .s404-cont h4 {
        font-size: 18px;
        width: 50%;
    }

    .s404-cont h6 {
        font-size: 14px;
        width: 50%;
        padding-top: 0px;
        padding-bottom: 15px;
    }

    .s404-btn-div {
        text-align: center;
        width: 50%;
    }

    .s404-card {
        background: url(../images/2413742.png) right;

        background-size: cover;
    }

    .s404-card img {
        display: none;
    }

    .s404-cont {
        position: absolute;
        top: 6%;
        left: 4%;
    }

    .notification-bar time {
        width: 17%;
    }

    .notification-bar h6 {
        width: 75%;
    }

    .sign-in input {
        padding: 8px 20px;
    }

    .modal-content.sign-in.otp {
        padding: 100px 50px 100px 50px !important;
    }

    .modal-content.sign-in.Forgot-Password {
        padding: 120px 50px 120px 50px !important;
    }

    .modal-content.sign-in.reset-Password {
        padding: 115px 83px 115px 78px !important;
    }
}

@media (max-width: 600px) {
    .pass-percentage {
        width: 175px;
        height: 110px;
        top: 20px;
        right: auto;
        left: 0%;
        position: relative;
    }

    .active-studentse {
        width: 175px;
        height: 110px;
        margin-top: 20px;
        top: 20px;
        left: 0;
        position: relative;
    }
    .home-banner-div-content {
        padding-bottom: 50px !important;
    }
    .home-banner-div-image img {
        padding-left: 6%!important;
        background: #fffdec;
    }
    .home-banner-div-content h4 {
        width: 100%;
    }
    .home-banner-div-content h5 {
        width: 100%;
    }
    .home-banner .col-md-5 {
        padding: 0px 0px!important;
    }
}

@media (max-width: 575px) {
    .perform-graph {
        margin-bottom: 30px;
    }


    .my-classes-sec, .saved-class-sec {
        padding-top: 25px;
    }


    .class-cont-rows {
        flex-direction: column;
        margin: 0;
    }

    .class-cont-rows h5 {
        margin-bottom: 10px;
    }


    .my-classes-sec .upcoming-class-card {
        margin-right: auto !important;
        width: 85% !important;
    }


    .my-classes-sec .upcoming-class-card:nth-child(2n + 2) {
        margin-right: auto !important;
    }

    .my-classes-sec .upcoming-class-card:nth-child(3n + 3) {
        margin-right: auto !important;
    }

    .testimonilas-img {
        left: -40px;
        width: 80px !important;
        height: 80px;
    }

    .home-reg-call h3 {
        font-size: 25px;
        padding-bottom: 25px;
        line-height: 35px;
    }

    .upcoming-class-card {
        width: 90% !important;
        margin: auto;
        margin-bottom: 30px;
    }

    .user-home-sec h4 {
        padding-left: 25px;
    }

    .user-home-sec h3 {
        padding-left: 25px;
    }

    .user-home-sec h3 {
        font-size: 30px;
        font-weight: 400;
        line-height: 50px;
        color: #000000;
        margin-bottom: 0;
        padding-bottom: 15px;
    }

    .user-home-sec h4 {
        padding-bottom: 0px;
    }

    .user-home-sec {
        padding-bottom: 20px;
    }

    .recent-class-card {
        width: 90% !important;
        margin: auto;
        margin-bottom: 20px;
    }

    .attach-btn {
        padding: 10px;
        font-size: 14px;
        width: 46%;
    }

    .mcq-btn {
        padding: 10px;
        font-size: 14px;
        width: 35%;
    }

    .watch-video-btn {
        padding: 10px;
        font-size: 14px;
    }

    .upcoming-class-join-btn,
    .upcoming-class-time-btn,
    .upcoming-class-day-btn {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 14px;
    }

    .user-home2-sec-title {
        font-size: 30px;
        padding-left: 25px !important;
    }

    .recent-class-title {
        font-size: 30px;
        padding-left: 25px !important;
        margin-bottom: 20px;
    }

    .user-home-perform-sec h4 {
        padding-left: 25px !important;
    }

    .class-count {
        padding-left: 15px !important;
        display: flex;
        flex-wrap: wrap;
    }

    .class-count-categ {
        width: 95%;
        margin: auto;
        margin-bottom: 15px;
    }

    .perf-categs {
        padding: 15px 25px 15px 25px !important;
    }

    .user-home2-sec {
        background: #3838381a;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .perf-categs {
        margin-bottom: 25px;
    }

    .section-title {
        font-size: 30px;
        line-height: 40px;
        padding-top: 30px;
        padding-bottom: 15px;
        padding-left: 25px !important;
    }

    .load-more-btn1,
    .load-more-btn2 {
        margin-top: 0px;
    }

    .my-classes-sec,
    .saved-class-sec {
        padding-bottom: 35px;
    }

    .user-home-recent-class {
        margin-top: 25px;
    }

    .system-check-list {
        width: 90% !important;
        margin: auto;
    }

    .system-check-list-item img {
        max-width: 30px;
    }

    .system-check-list-item-text h5,
    .system-check-list-item-text h6 {
        font-size: 14px;
    }

    .system-check-list-item {
        padding: 25px 23px 24px 20px !important;
    }

    .system-check-list-item-text {
        padding-left: 20px;
    }

    .system-check-title,
    .my-tutors-title {
        padding-left: 30px !important;
    }

    .system-check-class-det-card {
        width: 90% !important;
        margin: auto;
        margin-bottom: 50px;
    }

    .system-check-class-info h6 {
        width: 90%;
    }

    .chats {
        width: 95%;
        margin: auto;
    }

    .chat-replay {
        width: 80% !important;
    }

    .chat-talk-cont {
        width: 80% !important;
    }

    .text-input-field {
        padding: 20px 25px 20px 20px !important;
    }

    .text-input-field img {
        width: 25px;
    }

    .notification-list {
        width: 95%;
        margin: auto !important;
    }

    .privacy-policy-title {
        padding-left: 30px !important;
    }

    .privacy-policy-card {
        padding: 30px 25px !important;
        width: 85% !important;
        margin: auto;
        background-size: 250px;
    }

    .privacy-policy-cont p {
        font-size: 14px;
        line-height: 22px;
    }

    .prof-edit-feld-title {
        width: 110px;
    }

    .prof-edit-feld {
        padding: 12px 20px 12px 15px;
    }

    .s404-cont h3 {
        font-size: 50px;
        width: 100%;
        line-height: 40px;
        margin-bottom: 0;
    }

    .s404-cont h4 {
        font-size: 18px;
        width: 100%;
    }

    .s404-cont h6 {
        font-size: 14px;
        width: 100%;
        padding-top: 0px;
        padding-bottom: 15px;
        margin-bottom: 0;
    }

    .s404-btn-div {
        text-align: center;
        width: 100%;
    }

    .s404-card {
        background: url(../images/2413742.png) bottom;

        background-size: cover;
        width: 90% !important;
        margin: 30px auto !important;
    }

    .s404-cont {
        position: absolute;
        top: 6%;
        left: 5%;
        right: 5%;
    }

    .sign-in h3 {
        font-size: 20px;
        line-height: 50px;
    }

    h3.class-title {
        font-size: 16px;
        line-height: 22px;
    }

}

@media (max-width: 475px) {
    footer.m-footer {
        padding: 55px 30px 40px 30px;
    }

    .home-banner-div-content h4 {
        font-size: 25px;
        line-height: 31px;
    }

    .home-banner-div-content h5 {
        font-size: 15px;
        line-height: 25px;
        padding-top: 5px;
    }

    .home-banner-div-content h6 {
        font-size: 14px;
        line-height: 20px;
    }

    .app-link {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .app-link img:last-child {
        margin-top: 10px;
    }

    .home-banner-div-content {
        padding-top: 40px !important;
        padding-bottom: 30px !important;
    }

    /* .pass-percentage {
        top: 480px;
        right: auto;
        left: 7%;
    } */

    /* .active-studentse {
        top: 670px;
        right: 7%;
    } */

    /* .pass-percentage {
        top: 350px;
      
        right: auto;
        left: 7%;
    } */

    /* .active-studentse {
        top: 540px;
        right: 7%;
    } */

    .app-link img {
        width: 100px;
    }

    .logo img {
        width: 100px;
    }

    .home-about h3 {
        font-size: 22px;
        padding-top: 30px;
        padding-bottom: 0px;
        margin-bottom: 0;
    }

    .home-about h5 {
        font-size: 14px;
        width: 95%;
    }

    .testimonilas-img {
        left: -30px;
        width: 60px !important;
        height: 60px;
    }

    .testimonilas-card {
        background: #ffffff;
        border-radius: 10px;
        padding: 30px 25px 25px 30px;
    }

    .testimonilas-card h6 {
        padding-top: 24px;
        font-size: 14px;
        line-height: 20px;
    }

    .testimonilas-card h2 {
        padding-left: 25px;
        padding-bottom: 0px;
    }

    .testimonilas-card h4 {
        padding-left: 25px;
    }

    .owl-carousel-testimonilas-card .owl-prev img,
    .owl-carousel-testimonilas-card .owl-next img {
        width: 15px !important;
    }

    .owl-carousel-testimonilas-card .owl-prev {
        left: -10px;
    }

    .owl-carousel-testimonilas-card .owl-next {
        right: -10px;
    }

    .owl-dots {
        margin-top: 16px;
    }

    .owl-dots button.owl-dot {
        height: 10px;
        width: 10px;
        margin: 5px;
    }

    .home-course h3 {
        padding-top: 12px;
        padding-bottom: 5px;
        font-size: 30px;
    }

    .home-reg-call {
        padding: 45px 40px 50px 40px;
    }

    .home-student-placed h3 {
        line-height: 35px;
    }

    .prof-edit-card {
        padding: 40px 30px 45px 40px !important;
    }

    .prof-edit-feld {
        width: 100%;
    }

    .reset-pass-btn {
        width: 100%;
    }

    .prof-edit-feld h6 {
        font-size: 14px;
    }

    .system-check-list-item {
        width: 100% !important;
        margin-top: 20px;
    }

    .system-check-list-item:first-child {
        margin-top: 0px;
    }

    .system-check-title,
    .my-tutors-title {
        padding-bottom: 10px;
        padding-left: 35px !important;
    }

    .system-check-list-item {
        padding: 15px 23px 15px 20px !important;
    }

    .system-check-class-tutor {
        display: flex;
        justify-content: center;
        padding-bottom: 15px;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
    }

    .system-check-class-tutor-det {
        padding-left: 25px;
        padding-right: 25px;
    }

    .system-check-class-tutor-det h5 {
        color: #000000;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        margin: 0;
        text-align: center;
    }

    .system-check-class-tutor-det h4 {
        color: #000000;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        margin: 0;
        margin-top: 10px;
    }

    .system-check-class-info h6 {
        font-size: 14px;
        line-height: 20px;
        width: 100%;
    }

    .start-class-btn {
        width: 100% !important;
    }

    .online-icon {
        width: 15px;
        height: 15px;
        top: 19px;
    }

    .my-tutors-card-list {
        width: 93%;
        margin: auto !important;
    }

    .chat-sec {
        padding-top: 175px;
        padding-bottom: 30px;
    }

    .contact-btn-row {
        flex-wrap: wrap;
    }

    .contact-card {
        padding: 30px !important;
    }

    .contact-card input[type="text"] {
        padding: 10px 20px;
    }

    .contact-card textarea {
        padding: 20px 20px;
    }

    .modal-content {
        padding: 40px 30px 35px 30px !important;
    }

    .sign-in-btn {
        padding: 10px;
        margin-top: 15px;
    }

    .modal-dialog {
        max-width: 91% !important;
    }

    .sign-in input {
        padding: 14px 20px;
    }

    .prof-edit-feld-title {
        width: 75px;
    }


    .notification-bar time {
        width: 26%;
    }

    .notification-bar h6 {
        width: 70%;
    }

    .notification-bar h6 {
        margin: 0;
        padding-left: 25px;
        font-size: 14px;
    }

    .modal-content.sign-in.reset-Password {
        padding: 115px 45px 115px 40px !important;
    }
}

@media (max-width: 375px) {
    /* .pass-percentage {
        top: 350px;
        right: auto;
        left: 7%;
        width: 160px;
        height: 105px;
    } */

    /* .active-studentse {
        top: 500px;
        right: 7%;
        width: 160px;
        height: 106px;
    } */

    .sign-in input {
        padding: 5px 15px;
    }

    .recent-class-sub-title {
        max-width: 175px;
    }
}

@media (max-width: 350px) {
    /* .pass-percentage {
        top: 320px;
        right: auto;
        left: 7%;
    }

    .active-studentse {
        top: 470px;
        right: 7%;
    } */

    .owl-carousel .owl-item img {
        display: none !important;
    }

    .class-cont-rows h5 {
        font-size: 14px;
    }

    .recent-class-cont-row-cont h6 {
        font-size: 14px;
    }

    .recent-class-cont-row-cont {
        margin-right: 0px;
    }
}

@media (max-height: 700px) {
    .login-page {
        height: 100vh !important;
    }
}


.mcq-sec-1 {
    margin-top: 140px;
}

.class-title-div {
    padding: 26px 0px 26px 62px;
    background: #4f0973;
    border: 2px solid #dedede;
    border-radius: 10px;
}

.class-title-div h3 {
    font-size: 24px;
}

.class-title-div h4 {
    font-size: 16px;
}

.class-title-div h3.class-title {
    color: #ffff;
    font-weight: 600;
    line-height: 32px;
    margin: 0;
    margin-bottom: 7px;
}

.class-title-div h4.class-topic {
    color: #ffff;
    font-weight: 400;
    line-height: 21px;
    margin: 0;
}

.class-title-div h4.class-topic span {
    font-weight: 600;
}

.mcq-qst-div {
    margin-top: 31px;
    padding: 46px 95px 55px 62px;

    border: 2px solid #dedede;
    border-radius: 10px;
    background: #ebebeb;
}

.mcq-qst-div h3 {
    color: #000000;
    font-weight: 600;
}

.mcq-qst-div h4 {
    padding-left: 26px;
    font-size: 16px;
    color: #393939;
    line-height: 28px;
    font-weight: 400;
}

.mcq-question {
    /* width: 24px; */
    -ms-transform: scale(2); /* IE 9 */
    -webkit-transform: scale(2); /* Chrome, Safari, Opera */
    transform: scale(2);
    margin-left: 7px;
    margin-bottom: 30px;
}

.mcq-question-last-child {
    -ms-transform: scale(2); /* IE 9 */
    -webkit-transform: scale(2); /* Chrome, Safari, Opera */
    transform: scale(2);
    margin-left: 7px;

    margin-bottom: 0px;
}

.mcq-qst-div label {
    padding-left: 28px;
    font-size: 16px;
    color: #393939;
    line-height: 28px;
}

.mcq-qst {
    margin-bottom: 23px;
}

.attended-qust.stp-1 {
    float: right;
    margin-top: 26px;
    margin-bottom: 34px;
    display: flex;
}

.attended-qust {
    /* float: right; */
    margin-top: 26px;
    margin-bottom: 34px;
    display: flex;
    justify-content: space-between;
}

.attended-qust-count {
    font-size: 18px;
    color: #000000;
    line-height: 24px;
    font-weight: 600;
    margin: 0;
    margin-right: 29px;
}

.attended-qust-div {
    display: flex;
    align-items: center;
}

.attended-qust .btn-next {
    background: #784bbc;
    border-radius: 10px;
    padding: 14px 57px;
    border: none;

    color: #ffffff;
    font-size: 20px;
    line-height: 27px;
    font-weight: 600;
}

.mcq-qst-Congr-div {
    margin-top: 31px;
    margin-bottom: 103px;
    padding: 41px 25px 49px 62px;

    border: 2px solid #dedede;
    border-radius: 10px;
    background: #EBEBEB;
}

.mcq-qst-Congr {
    display: flex;
    flex-wrap: nowrap;
}

.popper-img {
    margin-top: 10px;
    padding: 0;
    width: 156px;
}

.mcq-qst-Congr-cont {
    padding: 0;
    padding-left: 73px;
}

.mcq-qst-Congr-cont h4 {
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    font-weight: 600;
    margin-bottom: 31px;
}

.mcq-qst-Congr-cont h5 {
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    font-weight: 500;
    margin-bottom: 31px;
}

.mcq-qst-Congr-cont h6 {
    font-size: 16px;
    line-height: 28px;
    color: #393939;
    font-weight: 400;
    margin: 0;
}

.btn-div {
    margin-top: 48px;
    padding: 0;
}

.btn-crr-answ {
    padding: 14px 49px;
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
    background: #784BBC;
    border: none;
    border-radius: 10px;
    color: #FFFFFF;
}

.btn-retry {
    padding: 14px 49px;
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
    background: #4F0973;
    border: none;
    border-radius: 10px;
    color: #FFFFFF;
    margin-left: 45px;
}

.mcq-qst-Corr-answers-div {
    margin-top: 31px;
    margin-bottom: 103px;
    padding: 41px 95px 80px 62px;

    border: 2px solid #dedede;
    border-radius: 10px;
    background: #EBEBEB;
}

.mcq-qst-Corr-answers-div h4 {
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    font-weight: 600;
    margin-bottom: 38px;
}

.mcq-qst {

}

.mcq-qst-desc {
    margin-left: 26px;
}

.mcq-qst-desc h5 {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;

}

.mcq-qst-desc h6 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: #000000;
    margin: 0;

}

.mcq-qst-desc span {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    padding-left: 20px;
    margin: 0;

}

.mcq-qst-list {
    margin-bottom: 24px;
}

/* Media qurey section */

@media (min-width: 1600px) {
    .container {
        max-width: 1500px !important;
    }
}

@media (min-width: 1800px) {
    .container {
        max-width: 1600px !important;
    }
}

@media (max-width: 992px) {
    .user-header-search input[type="search"] {
        width: 300px;
    }
}

@media screen and (max-width: 768px) {
    .navTrigger {
        display: block;
    }

    .nav div.logo {
        margin-left: 15px;
    }

    .nav div.main_list {
        width: 100%;
        max-height: 0;
        overflow: hidden;

        position: absolute;
        left: 0;
        top: 90px;

        transition:max-height 0.3s ease-out; 
    }

    .nav div.show_list {
        max-height: 100vh;
        /* display: none; */
        transition:max-height 0.3s ease-out;

        
    }

    .nav div.main_list ul {
        flex-direction: column;
        width: 100%;
        height: 100vh;
        right: 0;
        left: 0;
        bottom: 0;
        padding-top: 50px;
        background-color: #383838e0;
        background-position: center top;
    }

    .nav div.main_list ul li {
        width: 100%;
        text-align: start;
        margin-left: 18%;
    }

    .nav div.main_list ul li a {
        text-align: center;
        width: 100%;
        padding: 10px 0px;
        line-height: 25px;
        font-size: 20px;
        font-weight: 400;
    }

    .nav div.media_button {
        display: block;
    }

    .nav div.main_list ul li a {
        color: #fffafa !important;
    }

    .nav {
        height: auto !important;
    }

    /* .navTrigger {
        height: 95px;
    } */

    .mcq-sec-1 {
        margin-top: 220px;
    }

    .user-header {
        margin-left: 0%;
        background: #383838e0;
    }

    .user-header-search,
    .profile {
        display: none;
    }

    .header-mob-list {
        display: block;
    }

    .user-mob-header-search {
        background: #ffffff;
        display: flex;
        width: 100%;
        margin-top: 19px;
        justify-content: space-around;
        align-items: center;
    }

    .user-mob-header-search input[type="search"] {
        width: 80% !important;
    }

    .user-mob-header-search input[type="search"] {
        border: 1px solid #707070;
        border-radius: 10px;
        /* padding: 9px 33px; */
        padding: 9px;
        height: 40px;
        width: 600px;
        text-align: end;
    }

    .user-mob-header-search input:focus-visible {
        outline: none;
    }

    .user-header {
        top: 155px !important;
    }

    .profile-logout {
        width: 190px;
        margin-top: 6px;
    }

    .header-mob-list {
        background: #4f0973 !important;
    }


    .mcq-qst-Congr {
        display: flex;
        flex-wrap: wrap;
    }

    .mcq-qst-Congr-cont {
        padding: 0;
        padding-left: 0px;
        padding-top: 15px;
    }

    .btn-retry {
        margin-top: 15px;
        margin-left: 0px;
    }
}

@media (max-width: 575px) {
    .class-title-div {
        padding: 25px 15px 25px 20px;
    }

    .mcq-qst-div {
        padding: 25px 15px 25px 20px;
    }

    .mcq-qst {
        margin-bottom: 15px;
    }

    .mcq-question {
        -ms-transform: scale(1.5);
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        margin-bottom: 20px;
    }

    .mcq-question-last-child {
        -ms-transform: scale(1.5);
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        margin-bottom: 0px;
    }

    .attended-qust .btn-next {
        font-size: 18px;
        padding: 10px 40px;
    }

    .mcq-qst-div h4 {
        padding-left: 15px;
        font-size: 15px;
        line-height: 25px;
    }

    .mcq-qst-Congr-div {
        padding: 41px 25px 49px 25px;
    }

    .btn-crr-answ {
        padding: 10px 25px;
        font-size: 16px;
    }

    .btn-retry {
        padding: 10px 25px;
        font-size: 16px;
    }

    .mcq-qst-Corr-answers-div {
        margin-top: 31px;
        margin-bottom: 50px;
        padding: 30px 30px 40px 25px;
    }
}

@media (max-width: 475px) {
    footer {
        padding: 55px 30px 40px 30px;
    }

    .logo img {
        width: 100px;
    }

    .attended-qust {
        flex-wrap: wrap;
        padding: 0px 10px;
    }

    .attended-qust-div {
        display: flex;
        align-items: center;
        float: right;
    }

    .attended-qust .btn-next {
        font-size: 14px;
        padding: 10px 20px;
    }

    .attended-qust-count {
        font-size: 16px;
        line-height: 20px;
        margin: 0;
        margin-right: 15px;
    }

    .mcq-qst-Congr-cont h4 {
        margin-bottom: 20px;
    }

    .mcq-qst-Congr-cont h5 {
        margin-bottom: 15px;
    }

}
