
.home-reg-contact select {

    background-image: url("../images/Icon awesome-caret-down.svg") !important;;

}

.attachment-popup-btn {

    background-image: url("../images/Icon awesome-caret-down.svg") !important;;

}


.contact-card input[type="file"] {
    background: url("../images/Icon material-attachmentcc.svg") !important;;
}

input[type="file"] + label {
    background: url("../images/Icon material-attachmentcc.svg") #ffffff !important;;
}

.privacy-policy-card {
    background: #ffffff url("../images/imageedit_2_6985981321www.png") no-repeat center !important;;

}
.s404-card {
    background: url("../images/2413742.png") right !important;;

}

.s404-card {
    background: url("../images/2413742.png") bottom !important;;

}

.uploader__file_input_label {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0 !important;
    transform: translate(-50%,-50%)
}

.uploader__btn_wrapper {
    top: 4px;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    z-index: 0 !important;
}